//import React, { useState } from 'react';
//import { useEffect } from 'react';
import HTMLFlipBook from "react-pageflip";
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Col, Container, Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
//import { NieveComponente } from './components/NieveComponente'

import './App.js';
import './Notes.js';
import './index.js';
import './Magazine.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


import imagef from "./imagenes/IcoFacebook.svg"
import imagei from "./imagenes/IcoInstagram.svg"
import imaget from "./imagenes/IcoTwitter.svg"
import finallogo from "./imagenes/Logofooter.png"

import image1 from "./imagenes/logosup.png"
import imagepdf from "./pagesvivepdf/sate84.pdf"
import page1 from "./pagesvive/1.jpg"
import page2 from "./pagesvive/2.jpg"
import page3 from "./pagesvive/3.jpg"
import page4 from "./pagesvive/4.jpg"
import page5 from "./pagesvive/5.jpg"
import page6 from "./pagesvive/6.jpg"
import page7 from "./pagesvive/7.jpg"
import page8 from "./pagesvive/8.jpg"
import page9 from "./pagesvive/9.jpg"
import page10 from "./pagesvive/10.jpg"
import page11 from "./pagesvive/11.jpg"
import page12 from "./pagesvive/12.jpg"
import page13 from "./pagesvive/13.jpg"
import page14 from "./pagesvive/14.jpg"
import page15 from "./pagesvive/15.jpg"
import page16 from "./pagesvive/16.jpg"
import page17 from "./pagesvive/17.jpg"
import page18 from "./pagesvive/18.jpg"
import page19 from "./pagesvive/19.jpg"
import page20 from "./pagesvive/20.jpg"
import page21 from "./pagesvive/21.jpg"
import page22 from "./pagesvive/22.jpg"
import page23 from "./pagesvive/23.jpg"
import page24 from "./pagesvive/24.jpg"
import page25 from "./pagesvive/25.jpg"
import page26 from "./pagesvive/26.jpg"
import page27 from "./pagesvive/27.jpg"
import page28 from "./pagesvive/28.jpg"
import page29 from "./pagesvive/29.jpg"
import page30 from "./pagesvive/30.jpg"
import page31 from "./pagesvive/31.jpg"
import page32 from "./pagesvive/32.jpg"
import page33 from "./pagesvive/33.jpg"
import page34 from "./pagesvive/34.jpg"
import page35 from "./pagesvive/35.jpg"
import page36 from "./pagesvive/36.jpg";

function Magazine(props) {
    const imageClick = () => {
        console.log('Click');
      }
    return (
        <div className="Magazine">
            <div className='img-sup-magazine'>
            {/*<NieveComponente />*/}
           <Container id="menunotes">
              <Navbar collapseOnSelect expand="lg"  className="justify-content-center" id="image-menu" >
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app">
                    <img src={image1} width={200} alt=""/>
                  </Nav.Link>
                </Nav>
                    <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuN">
                      <Nav.Item as="li">
                        <Nav.Link href="?h=app" className="menu" id="menuizquierda">Inicio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link href="?v=videos" eventKey="link-2" className="menu" id="videos">Videos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link href="https://wa.me/5215612818956" className="menu" id="contactom">Contacto</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="icons-redes">
                    <Nav >
                      <Nav.Link>
                        <img src={imagef} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imagei} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imaget} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                      </Nav.Link>
                    </Nav> 
                    </div>
              </Navbar>
            </Container>
            <Container  id="menuresposivenotesM">
            <Navbar collapseOnSelect expand="lg"  className="justify-content-center">
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
                <Nav className="mr-auto">
                  <Nav.Link>
                    <img src={image1} width={280} alt=""/>
                  </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link >
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuM">
              <Nav.Item as="li">
                <Nav.Link href="?h=app" className="menuM" id="menuizquierdaM">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="?v=videos" eventKey="link-2" className="menuM" id="videosM">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="https://wa.me/5215612818956" className="menuM">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
            </Container>
            </div>
            <Container className="magazine-book">
                <Row>
                    <Col>
                        <HTMLFlipBook className="flipbook" width={550} height={700}>
                            <div className="page0"></div>
                            <div className="page1"><Image src={page1}width="100%"height="100%"/></div>
                            <div className="page2"><Image src={page2}width="100%"height="100%"/></div>
                            <div className="page3"><Image src={page3}width="100%"height="100%"/></div>
                            <div className="page4"><Image src={page4}width="100%"height="100%"/></div>
                            <div className="page5"><Image src={page5}width="100%"height="100%"/></div>
                            <div className="page6"><Image src={page6}width="100%"height="100%"/></div>
                            <div className="page7"><Image src={page7}width="100%"height="100%"/></div>
                            <div className="page8"><Image src={page8}width="100%"height="100%"/></div>
                            <div className="page9"><Image src={page9}width="100%"height="100%"/></div>
                            <div className="page10"><Image src={page10}width="100%"height="100%"/></div>
                            <div className="page11"><Image src={page11}width="100%"height="100%"/></div>
                            <div className="page12"><Image src={page12}width="100%"height="100%"/></div>
                            <div className="page13"><Image src={page13}width="100%"height="100%"/></div>
                            <div className="page14"><Image src={page14}width="100%"height="100%"/></div>
                            <div className="page15"><Image src={page15}width="100%"height="100%"/></div>
                            <div className="page16"><Image src={page16}width="100%"height="100%"/></div>
                            <div className="page17"><Image src={page17}width="100%"height="100%"/></div>
                            <div className="page18"><Image src={page18}width="100%"height="100%"/></div>
                            <div className="page19"><Image src={page19}width="100%"height="100%"/></div>
                            <div className="page20"><Image src={page20}width="100%"height="100%"/></div>
                            <div className="page21"><Image src={page21}width="100%"height="100%"/></div>
                            <div className="page22"><Image src={page22}width="100%"height="100%"/></div>
                            <div className="page23"><Image src={page23}width="100%"height="100%"/></div>
                            <div className="page24"><Image src={page24}width="100%"height="100%"/></div>
                            <div className="page25"><Image src={page25}width="100%"height="100%"/></div>
                            <div className="page26"><Image src={page26}width="100%"height="100%"/></div>
                            <div className="page27"><Image src={page27}width="100%"height="100%"/></div>
                            <div className="page28"><Image src={page28}width="100%"height="100%"/></div>
                            <div className="page29"><Image src={page29}width="100%"height="100%"/></div>
                            <div className="page30"><Image src={page30}width="100%"height="100%"/></div>
                            <div className="page31"><Image src={page31}width="100%"height="100%"/></div>
                            <div className="page32"><Image src={page32}width="100%"height="100%"/></div>
                            <div className="page33"><Image src={page33}width="100%"height="100%"/></div>
                            <div className="page34"><Image src={page34}width="100%"height="100%"/></div>
                            <div className="page35"><Image src={page35}width="100%"height="100%"/></div>
                            <div className="page36"><Image src={page36}width="100%"height="100%"/></div>
                        </HTMLFlipBook>
                    </Col>
                </Row>
            </Container>
            <Container>
            <Col className="magazine-pdf" >
                    <div class="carousel-wrapper">
                    <TransformWrapper>
              <TransformComponent>
            <Carousel className="carousel-imgs">
                <div><img src={page1} /></div>
                <div><img src={page2} /></div>
                <div><img src={page3} /></div>
                <div><img src={page4} /></div>
                <div><img src={page5} /></div>
                <div><img src={page6} /></div>
                <div><img src={page7} /></div>
                <div><img src={page8} /></div>
                <div><img src={page9} /></div>
                <div><img src={page10} /></div>
                <div><img src={page11} /></div>
                <div><img src={page12} /></div>
                <div><img src={page13} /></div>
                <div><img src={page14} /></div>
                <div><img src={page15} /></div>
                <div><img src={page16} /></div>
                <div><img src={page17} /></div>
                <div><img src={page18} /></div>
                <div><img src={page19} /></div>
                <div><img src={page20} /></div>
                <div><img src={page21} /></div>
                <div><img src={page22} /></div>
                <div><img src={page23} /></div>
                <div><img src={page24} /></div>
                <div><img src={page25} /></div>
                <div><img src={page26} /></div>
                <div><img src={page27} /></div>
                <div><img src={page28} /></div>
                <div><img src={page29} /></div>
                <div><img src={page30} /></div>
                <div><img src={page31} /></div>
                <div><img src={page32} /></div>
                <div><img src={page33} /></div>
                <div><img src={page34} /></div>
                <div><img src={page35} /></div>
                <div><img src={page36} /></div>
               
            </Carousel>
            </TransformComponent>
        </TransformWrapper>
        </div>
                    </Col>
            </Container>
            <Container className="cont-final" id="contactomagazine">
        <Container>
          <Row>
            <Col>
              <div className="text-privacidad">
                <ul>
                <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
                </ul>
              </div>
            </Col>
            <Col>
              <div  className="cont-logo-footer">
                <img src={finallogo} alt=""/>
              </div>
              <div className="text-logo-foot">
                <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
              </div>
            </Col>
            <Col>
              <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                  </Nav.Link>
                </Nav>
              </div>
              <div className="text-contacto-final">
                <p><strong>Contacto</strong></p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* responsivo*/}

      <Container  className="container-final-responsive">
        <Row>
          <Col className="privacidad-responsive">
            <ul>
            <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
            </ul>
          </Col>
          <Col>
            <div  className="logo-responsive">
              <img src={finallogo} alt="" width={150}/>
            </div> 
            <div>
              <Nav className="icons-redes-ultimos">
                <Nav.Link  className="">
                  <img src={imagef} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imagei} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imaget} width={32} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="derechos-reservados-resposive">
            <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
          </div>
        </Row>
      </Container>
        </div>
    );
}
export default Magazine;
