import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Notes.js';
import './Magazine.js';
import './Videos.js';
import'./App.js';
import './Privacidad.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { BrowserRouter } from 'react-router-dom';
import Notes from './Notes';
import Magazine from './Magazine';
import Videos from './Videos';
import Privacidad from './Privacidad';


var url_string = window.location.href
var url = new URL(url_string);
var p = url.searchParams.get("p");
var id = url.searchParams.get("id");
var m = url.searchParams.get("m");
var v = url.searchParams.get("v");
var h = url.searchParams.get("h");
var a = url.searchParams.get("a");


if(p != undefined) {
  ReactDOM.render(
    <React.StrictMode>        
      <Notes id={id}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}else if ( m  != undefined){
  ReactDOM.render(
    <React.StrictMode> 
       <Magazine/>
    </React.StrictMode>, 
    document.getElementById('root')
  );
}else if ( v  != undefined){
  ReactDOM.render(
    <React.StrictMode> 
       <Videos/>
    </React.StrictMode>, 
    document.getElementById('root')
  );
}else if( h  != undefined){
  ReactDOM.render(
    <React.StrictMode> 
      <App />
    </React.StrictMode>, 
    document.getElementById('root')
  );
}else if( a  != undefined){
ReactDOM.render(
  <React.StrictMode> 
    <Privacidad />
  </React.StrictMode>, 
  document.getElementById('root')
);
}else{
ReactDOM.render(
  <React.StrictMode> 
    <App />
  </React.StrictMode>, 
  document.getElementById('root')
);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
